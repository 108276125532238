import _modes from "./lib/modes";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function (stream, cb) {
  return new Parser(stream, cb);
};

function Parser(stream, cb) {
  var self = this || _global;
  self.stream = stream;
  self.cb = cb;

  self._onData = function (buf) {
    self.execute(buf, 0, buf.length);
  };

  stream.on("data", self._onData);
  (this || _global).mode = "begin";
}

Parser.prototype.execute = function (buf, start, len) {
  for (var i = start; i < len && i >= 0;) {
    i = (this || _global).modes[(this || _global).mode].call(this || _global, buf, i, len - i);

    if (i < 0) {
      (this || _global).stream.removeListener("data", (this || _global)._onData);

      if ((this || _global).request) {
        var err = new Error("error parsing " + (this || _global).mode);

        (this || _global).request.emit("error", err);
      }

      break;
    }
  }
};

Parser.prototype.upgrade = function () {
  var self = this || _global;

  if (self.request && (self.mode === "begin" || self.mode === "method" || self.mode === "url" || self.mode === "versionBegin" || self.mode === "version" || self.mode === "headerField" || self.mode === "headerValue")) {
    self.mode = "upgrade";
    self.request.emit("upgrade");
  } else if (self.request) {
    self.request.once("headers", function () {
      self.mode = "upgrade";
      self.request.emit("upgrade");
    });
  } else {
    var cb = self.cb;

    self.cb = function () {
      cb.apply(this || _global, arguments);
      self.upgrade();
    };
  }

  var ended = false;
  self.stream.on("end", function () {
    if (self.request) self.request.emit("end");
  });
};

Parser.prototype.modes = _modes;
export default exports;