import _http from "http";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var http = _http;
var IncomingMessage = http.IncomingMessage;

exports.begin = function (buf, start, len) {
  (this || _global).request = new IncomingMessage((this || _global).stream);
  this.cb((this || _global).request);
  (this || _global).mode = "method";
  return start;
};

exports.method = function (buf, start, len) {
  var req = (this || _global).request;
  if (req.method == null) req.method = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === " ") {
      (this || _global).mode = "url";
      i++;
      break;
    } else if (c === "\n") {
      return -1;
    } else {
      req.method += c.toUpperCase();
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

exports.url = function (buf, start, len) {
  var req = (this || _global).request;

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === " ") {
      (this || _global).mode = "versionBegin";
      i++;
      break;
    } else if (c === "\n") {
      return -1;
    } else {
      req.url += c;
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

exports.versionBegin = function (buf, start, len) {
  var req = (this || _global).request;
  if ((this || _global)._httpVersionPrelude === undefined) (this || _global)._httpVersionPrelude = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === "/") {
      if ((this || _global)._httpVersionPrelude !== "HTTP") {
        return -1;
      } else {
        (this || _global).mode = "version";
        (this || _global)._httpVersionPrelude = "";
        i++;
        break;
      }
    } else if (c === "\n") {
      return -1;
    } else {
      (this || _global)._httpVersionPrelude += c;
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

exports.version = function (buf, start, len) {
  var req = (this || _global).request;
  if (req.httpVersion == null) req.httpVersion = "";
  if (req.method == null) req.method = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === " ") {
      return -1;
    } else if (c === "\n") {
      req.httpVersion = req.httpVersion.trim();
      var major = req.httpVersionMajor = Math.floor(req.httpVersion);
      var minor = req.httpVersionMinor = Number(req.httpVersion) - major;

      if (isNaN(major) || isNaN(minor)) {
        return -1;
      }

      (this || _global).mode = "headerField";
      i++;
      break;
    } else {
      req.httpVersion += c;
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

exports.headerField = function (buf, start, len) {
  var req = (this || _global).request;
  if ((this || _global)._field === undefined) (this || _global)._field = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === ":") {
      (this || _global).mode = "headerValue";
      i++;
      break;
    } else if (c === "\n") {
      if ((this || _global)._field === "" || (this || _global)._field === "\r") {
        (this || _global)._field = "";
        req.emit("rawHead", buf.slice(start, i + 1));
        normalizeHeaders.call(this || _global, req);
        req.emit("headers", req.headers);
        exports.body.call(this || _global);
        return i + 1;
      } else return -1;
    } else {
      (this || _global)._field += c;
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

exports.headerValue = function (buf, start, len) {
  var req = (this || _global).request;
  if ((this || _global)._value === undefined) (this || _global)._value = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === "\n") {
      var field = (this || _global)._field.trim().toLowerCase();

      var value = (this || _global)._value.trim();

      (this || _global)._field = undefined;
      (this || _global)._value = undefined;

      switch (field) {
        case "connection":
        case "transfer-encoding":
        case "expect":
          value = value.toLowerCase();
          break;
      }

      (this || _global).request._addHeaderLine(field, value);

      (this || _global).mode = "headerField";
      i++;
      break;
    } else {
      (this || _global)._value += c;
    }
  }

  req.emit("rawHead", buf.slice(start, i));
  return i;
};

function normalizeHeaders(req) {
  var clen = req.headers["content-length"];

  if (clen !== undefined) {
    req.headers["content-length"] = parseInt(clen);
  }

  (this || _global)._expectContinue = req.headers["expect"] !== undefined && req.httpVersionMajor === 1 && req.httpVersionMinor === 1 && req.headers["expect"] === "100-continue";
  var ver = parseFloat(req.httpVersion);

  if (req.headers["transfer-encoding"]) {
    (this || _global)._useChunkedEncoding = req.headers["transfer-encoding"] === "chunked";
  } else if (clen !== undefined) {
    (this || _global)._useChunkedEncoding = false;
  } else {
    (this || _global)._useChunkedEncoding = ver >= 1.1;
  }

  if (req.headers["connection"] !== undefined) {
    (this || _global)._shouldKeepAlive = req.headers["connection"] === "keep-alive";
  } else {
    (this || _global)._shouldKeepAlive = ver >= 1.1;
  }
}

exports.body = function (buf, start, len) {
  var req = (this || _global).request;
  var enc = req.headers["transfer-encoding"];

  if ((this || _global).mode === "upgrade") {
    /* ... */
  } else if (req.method === "POST" || req.method === "PUT") {
    if ((this || _global)._useChunkedEncoding) {
      (this || _global).mode = "chunk";
    } else if ((this || _global)._expectContinue) {
      (this || _global).mode = "body"; // for now
    } else if (req.headers["content-length"] !== undefined) {
      (this || _global)._pendingBytes = req.headers["content-length"];
      (this || _global).mode = "readLen";
    } else return -1;
  } else if (req.headers.upgrade) {
    (this || _global).mode = "upgrade";
  } else {
    if (!(this || _global)._ended) req.emit("end");
    (this || _global).mode = (this || _global)._shouldKeepAlive ? "begin" : "finished";
  }

  return start;
};

exports.finished = function (buf, start, len) {
  return -1;
};

exports.chunk = function (buf, start, len) {
  var req = (this || _global).request;
  if ((this || _global)._pendingHex === undefined) (this || _global)._pendingHex = "";

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === "\n") {
      (this || _global)._pendingBytes = parseInt((this || _global)._pendingHex.trim(), 16);
      (this || _global)._pendingHex = undefined;

      if ((this || _global)._pendingBytes === 0) {
        (this || _global).request.emit("end");

        (this || _global).mode = "lastCRLF";
      } else {
        (this || _global).mode = "chunkBody";
      }

      i++;
      break;
    } else {
      (this || _global)._pendingHex += c;
    }
  }

  req.emit("rawBody", buf.slice(start, i));
  return i;
};

exports.lastCRLF = function (buf, start, len) {
  var ended = false;

  for (var i = start; i < start + len; i++) {
    var c = String.fromCharCode(buf[i]);

    if (c === "\n") {
      ended = true;
      (this || _global).mode = (this || _global)._shouldKeepAlive ? "begin" : "finished";
      i++;
      break;
    }
  }

  var req = (this || _global).request;
  req.emit("rawBody", buf.slice(start, i));
  if (ended) req.emit("rawEnd");
  return i;
};

exports.chunkBody = function (buf, start, len) {
  if ((this || _global)._pendingBytes === 0) {
    for (var i = start; i < start + len; i++) {
      var c = String.fromCharCode(buf[i]);

      if (c === "\n") {
        (this || _global).mode = "chunk";
        i++;
        break;
      } else if (c !== "\r") {
        return -1;
      }
    }

    (this || _global).request.emit("rawBody", buf.slice(start, i));

    return i;
  }

  var req = (this || _global).request;
  var read = 0;

  if (len <= (this || _global)._pendingBytes) {
    if (start === 0) {
      req.emit("data", buf);
    } else {
      req.emit("data", buf.slice(start, start + len));
    }

    (this || _global)._pendingBytes -= len;
    read = len;
  } else {
    req.emit("data", buf.slice(start, start + (this || _global)._pendingBytes));
    read = (this || _global)._pendingBytes;
    (this || _global)._pendingBytes = 0;
  }

  req.emit("rawBody", buf.slice(start, start + read));
  return start + read;
};

exports.readLen = function (buf, start, len) {
  var req = (this || _global).request;
  var slice = null;

  if (len <= (this || _global)._pendingBytes) {
    if (start === 0) {
      slice = buf;
    } else {
      slice = buf.slice(start, start + len);
    }

    (this || _global)._pendingBytes -= len;
  } else {
    slice = buf.slice(start, start + (this || _global)._pendingBytes);
    (this || _global)._pendingBytes = 0;
  }

  req.emit("rawBody", slice);
  req.emit("data", slice);

  if ((this || _global)._pendingBytes === 0) {
    (this || _global).request.emit("end");

    req.emit("rawEnd");
    (this || _global).mode = (this || _global)._shouldKeepAlive ? "begin" : "finished";
  }

  return start + slice.length;
};

exports.upgrade = function (buf, start, len) {
  var req = (this || _global).request;
  var slice = buf.slice(start, start + len);
  req.emit("rawBody", slice);
  req.emit("data", slice);
  return start + len;
};

export default exports;